<template>
	<div id="classroom">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教室管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item"><el-button size="small" @click="openDialog(1)">添加教室</el-button></div>
				</div>
				<el-table :data="StaffList" height="72.9vh" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="code" label="教室代码" align="center" width="120"></el-table-column>
					<el-table-column prop="name" label="教室名称" align="center" width="150"></el-table-column>
					<el-table-column prop="school_campus.name" label="校区名称" width="150" align='center'></el-table-column>
					<el-table-column prop="area" label="教室面积" width="150" align='center'></el-table-column>
					<el-table-column prop="administrators.nickname" label="管理员" width="150" align='center'></el-table-column>
					<el-table-column prop="max" label="容纳人数" width="150" align='center'></el-table-column>
					<el-table-column prop="location" label="位置" align='center'></el-table-column>
					<el-table-column prop="status_text" label="状态" width="150" align='center'></el-table-column>
					<el-table-column label="操作" width="150" align="center">
						<template slot-scope="scope">
							<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="delSubject(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加课室 -->
			<el-dialog :title="showTitle" :visible.sync="addSubject" width="500px" :before-close="handleClose">
				<!--选择 -->
				<div class="selectShow">
					<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose1" append-to-body>
						<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
						<div class="user-list-box">
							<div class="u-f-item u-f-jsb user-list-head">
								<div class="user-type-list u-f-item">
									<div
										class="user-type-item u-f-justify"
										@click="typeTap(index)"
										:class="typeIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list"
										:key="index"
									>
										{{ item.name }}
										<span v-if="index + 1 < teacher_list.length">|</span>
									</div>
								</div>
								<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
							</div>
							<div class="user-list-content u-f">
								<div class="u-f2 left" v-if="teacher_list.length">
									<div
										class="left-item"
										@click="dataTap(index)"
										:class="dataIndex == index ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data"
										:key="index"
									>
										{{ item.name }}
									</div>
								</div>
								<div class="u-f4 right" v-if="teacher_list.length">
									<div class="list" v-if="teacher_list[typeIndex].data[dataIndex]">
										<div
											class="item"
											@click="teachTap(item)"
											:class="item.checked ? 'active' : ''"
											v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
										>
											{{ item.username }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
							<el-button @click="reset" size="small">重置</el-button>
							<div class="u-f-item">
								<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
								<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
							</div>
						</span>
					</el-dialog>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							教室代码
						</div>
						<el-input v-model="code" placeholder="请输入教室代码" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							教室名称
						</div>
						<el-input v-model="name" placeholder="请输入教室名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							所属校区
						</div>
						<el-select v-model="school_campus_id" filterable placeholder="请选择" style="width: 75%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							位置
						</div>
						<el-input v-model="location" placeholder="请输入教室位置" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							面积
						</div>
						<el-input v-model="area" placeholder="请输入面积" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							容纳人数
						</div>
						<el-input-number v-model="max" :min="0" label="请输入容纳人数"></el-input-number>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							管理员
						</div>
						<div class="u-f" style="width: 75%;">
							<el-select v-model="administrators_id"  popper-class="el_option" style="width:90%;">
								<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
							</el-select>
							<el-button style="margin-left: 10px;height: 40px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							是否试训室
						</div>
						<el-radio-group v-model="whether">
							<el-radio-button :label="1">是</el-radio-button>
							<el-radio-button :label="0">否</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							是否有效
						</div>
						<el-radio-group v-model="status">
							<el-radio-button :label="1">有效</el-radio-button>
							<el-radio-button :label="0">无效</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">备注</div>
						<el-input type="textarea" :rows="5" v-model="mark" placeholder="请输入备注" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			StaffList: [],
			addSubject: false,
			loading: false,
			editid: '',
			page: 1,
			limit: 11,
			total: 0,
			subjectTypeList: [],
			code: '',
			name: '',
			school_campus_id: '',
			location: '',
			area:'',
			max:'',
			mark:'',
			showTitle: '添加教室',
			showType: 1,
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			administrators_id: '',
			nums: [],
			selectShow: false,
			selectList: [],
			isBnt:false,
			subjectList:[],
			whether:1,
			status:1
		};
	},
	mounted() {
		this.getInfo();
		this.teacherList();
		this.schoolCampusIndex()
	},
	methods: {
		handleClose1(done){
			this.typeIndex = 0;
			this.dataIndex = 0;
			done()
		},
		// 校区信息
		schoolCampusIndex() {
			this.$api.setting
				.schoolCampusIndex({})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.subjectList = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 重置
		reset() {
			this.administrators_id = '';
			this.teacherList();
		},
		// 选择老师
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.administrators_id = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		twacherTap(index) {
			this.MeunIndex = index;
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.showType = type;
			if (type == 1) {
				this.showTitle = '添加教室';
			} else if (type == 2) {
				this.showTitle = '编辑教室';
				this.editid = item.id;
				this.code = item.code;
				this.name = item.name;
				this.school_campus_id = item.school_campus_id;
				this.location = item.location;
				this.area = item.area;
				this.max = item.max;
				this.whether = item.whether;
				this.administrators_id = item.administrators_id;
				this.status = item.status;
				this.mark = item.mark;
			}
			this.addSubject = true;
		},
		init() {
			this.code = '';
			this.name = '';
			this.school_campus_id = '';
			this.location = '';
			this.area = '';
			this.max = '';
			this.whether = 1;
			this.administrators_id = '';
			this.status = 1;
			this.mark = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		// 教研室列表
		getInfo() {
			this.$api.setting
				.getClassRoom({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.StaffList = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 添加学科
		submit() {
			if(this.showType==1){
				this.addStaff()
			}else{
				this.edit()
			}
		},
		addStaff(){
			if (this.code == '' || !this.code) {
				return this.$message.error('请输入教室代码');
			}
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入教室名称');
			}
			this.$api.setting
				.addClassRoom({
					code: this.code,
					name: this.name,
					school_campus_id:this.school_campus_id,
					location:this.location,
					area:this.area,
					max:this.max,
					whether:this.whether,
					administrators_id:this.administrators_id,	
					status:this.status,
					mark:this.mark
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		edit() {
			if (this.code == '' || !this.code) {
				return this.$message.error('请输入教室代码');
			}
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入教室名称');
			}
			this.$api.setting
				.editClassRoom({
					code: this.code,
					name: this.name,
					school_campus_id:this.school_campus_id,
					location:this.location,
					area:this.area,
					max:this.max,
					whether:this.whether,
					administrators_id:this.administrators_id,	
					status:this.status,
					mark:this.mark,
					id:this.editid
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除学科
		delSubject(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delClassRoom({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getInfo();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
#classroom {
	.content {
		margin-top: 15px;
		background-color: #ffffff;
		height: 86vh;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
